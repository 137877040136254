'use strict';

import axios from 'axios';
import './style.css';

export class ReviewsElements {
    constructor() {
        this.clicked = false;
        this.rateStars = document.querySelectorAll('.custom-rate .rate .rate__star');
        this.replyLinks = document.querySelectorAll('.reviews-block__reply');
        this.closeForms = document.querySelectorAll('.forgot-password');
        this.addReviewsForm = document.getElementById('add-reviews_form');
        this.answerForms = document.querySelectorAll('form#add-reviews_answer_form');

        this.setupRateStars();
        this.setupReplyLinks();
        this.setupCloseForm();
        this.setupAddReviewsForm();
        this.setupAnswerForms();
    }

    setupRateStars() {
        if (this.rateStars.length > 0) {
            this.rateStars.forEach((star, index) => {
                star.addEventListener('click', (e) => {
                    this.handleRateStarClick(star, index);
                });
            });
        }
    }

    handleRateStarClick(star, index) {
        const parent = star.parentElement;
        parent.setAttribute('data-rate', index + 1);

        const formRateInput = document.querySelector('input[name="form_rate"]');
        if (formRateInput) {
            formRateInput.value = index + 1;
        }

        this.clicked = true;
    }

    setupReplyLinks() {
        if (this.replyLinks.length > 0) {
            this.replyLinks.forEach(replyLink => {
                replyLink.addEventListener('click', (e) => {
                    this.handleReplyLinkClick(replyLink);
                });
            });
        }
    }

    setupCloseForm() {
        if (this.closeForms.length > 0) {
            this.closeForms.forEach(closeForm => {
                closeForm.addEventListener('click', (e) => {
                    this.handleCloseLinkClick(closeForm);
                });
            });
        }
    }

    handleCloseLinkClick(closeForm) {
        // Находим родительский блок для текущей ссылки
        var parentReviewsBlock = closeForm.closest('.reviews-block__add-answer');

        if (parentReviewsBlock) {
            parentReviewsBlock.style.display = 'none';
        }
    }

    handleReplyLinkClick(replyLink) {
        const reviewsBottom = replyLink.closest('.reviews-block__bottom');
        const addAnswer = reviewsBottom.nextElementSibling;

        if (addAnswer && addAnswer.classList.contains('reviews-block__add-answer')) {
            addAnswer.style.display = addAnswer.style.display === 'none' ? 'block' : 'none';
        }
    }

    setupAddReviewsForm() {
        if (this.addReviewsForm) {
            this.addReviewsForm.addEventListener('submit', (evt) => {
                this.handleFormSubmit(evt);
            });
        }
    }

    setupAnswerForms() {
        if (this.answerForms.length > 0) {
            this.answerForms.forEach(form => {
                form.addEventListener('submit', (evt) => {
                    this.handleFormSubmit(evt);
                });
            });
        }
    }

    handleFormSubmit(evt) {
        evt.preventDefault();
        this.sendReviewsForm(evt);
        return false;
    }

    updateLocalStorage = (key, newValue) => {
        const existingValue = localStorage.getItem(key);
        if (existingValue !== newValue) {
            localStorage.setItem(key, newValue);
        }
    };

    sendReviewsForm(evt) {
        const form = evt.target;
        const dataPath = form.getAttribute('data-path');
        const formData = new FormData(form);
        const userEmail = form.elements['email'].value.trim();

        axios.post(`${dataPath}/save_review.php`, formData)
            .then(response => {
                // Обработка успешного ответа
                if (response.data === "captcha_error") {
                    const errorElement = form.querySelector('.captcha-error-form-send-text');
                    if (errorElement) {
                        errorElement.style.display = 'block';
                    }
                } else if (response.data === "success") {
                    form.style.display = 'none';
                    const successMessage = form.nextElementSibling;

                    if (successMessage && successMessage.classList.contains('success-form-send-text')) {
                        successMessage.style.display = 'block';
                    }

                    const errorElement = form.querySelector('.captcha-error-form-send-text');
                    if (errorElement) {
                        errorElement.style.display = 'none';
                    }

                    this.updateLocalStorage('user_data.email_address', userEmail);
                }
            })
            .catch(error => {
                // Обработка ошибок при отправке запроса
                console.error(error);
                document.getElementById('form_success_message').style.display = 'none';
            });
    }
}